import { AudiencesQuintile } from '@workspaces/types'
import {
  LayerObject,
  TransparentColor,
  QuintileColors,
  QuintileLineColors,
} from './audiences-quintile-layer.types'

export function filterElements(
  object: LayerObject,
  zipCodesByQuintiles: Map<number, AudiencesQuintile.ZipCodeInfo>,
) {
  const zipCodeValue = object.properties.name
  const isZipcodeInData = zipCodesByQuintiles.has(zipCodeValue)
  if (isZipcodeInData) {
    return 1
  } else {
    return 0
  }
}

export function getFilledColor(
  object: LayerObject,
  zipCodesByQuintiles: Map<number, AudiencesQuintile.ZipCodeInfo>,
  selectedQuintiles: number[],
): Uint8Array {
  const zipCodeValue = object.properties.name
  const zipCodeInfo = zipCodesByQuintiles.get(zipCodeValue)
  if (!zipCodeInfo) {
    return TransparentColor
  }

  const quintile = zipCodeInfo.quintile
  if (selectedQuintiles.includes(quintile)) {
    return QuintileColors[quintile - 1]
  }

  return TransparentColor
}

export function getLineWidth(
  object: LayerObject,
  zipCodesByQuintiles: Map<number, AudiencesQuintile.ZipCodeInfo>,
) {
  const zipCodeValue = object.properties.name
  const zipCodeInfo = zipCodesByQuintiles.get(zipCodeValue)
  if (!zipCodeInfo) {
    return 0
  } else {
    return 34
  }
}

export function getLineColor(
  object: LayerObject,
  zipCodesByQuintiles: Map<number, AudiencesQuintile.ZipCodeInfo>,
  selectedQuintiles: number[],
): Uint8Array {
  const zipCodeValue = object.properties.name
  const zipCodeInfo = zipCodesByQuintiles.get(zipCodeValue)
  if (!zipCodeInfo) {
    return TransparentColor
  }

  const quintile = zipCodeInfo.quintile
  if (selectedQuintiles.includes(quintile)) {
    return QuintileLineColors[quintile - 1]
  }

  return TransparentColor
}
