import { Asset, Environment, Metadata, Package } from '@workspaces/types'
import { getPackageAvailability } from '@/services/packages-browser.service'
import { getPriceValueUpdated, preparePriceModel } from './price.helper'
import layerService from '@/layers/layerService'

/**
 * Get the availability of a list of packages
 * @param packages  List of packages to resolve the availability
 * @returns High if all packages have high availability, Low if all packages have low availability, Medium if the availability is mixed
 */
function resolvePackagesAvailability(
  packages: Package.PackageDetail[],
): number | null {
  if (packages.length === 0) {
    return null
  }

  // Values for ranges are: 1, 41, 91
  const nomralizedConstantValues = [1, 41, 91]
  let totalAvailability = 0
  packages.forEach((pkg) => {
    if (pkg.availability) {
      if (pkg.availability < 40) {
        totalAvailability += nomralizedConstantValues[0]
      } else if (pkg.availability < 90) {
        totalAvailability += nomralizedConstantValues[1]
      } else {
        totalAvailability += nomralizedConstantValues[2]
      }
    }
  })

  if (totalAvailability === nomralizedConstantValues[0] * packages.length) {
    return nomralizedConstantValues[0]
  } else if (
    totalAvailability === nomralizedConstantValues[2] * packages.length ||
    totalAvailability === 0
  ) {
    return nomralizedConstantValues[2]
  } else {
    return nomralizedConstantValues[1]
  }
}

export async function getPackagesAvailability(
  appMetadata: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
  packages: Package.PackageDetail[],
  dateRange?: [string, string] | null,
): Promise<number | null> {
  if (!dateRange || dateRange.length !== 2) {
    return null
  }

  if (!packages || packages.length === 0) {
    return null
  }

  const [startDate, endDate] = dateRange
  const availabilityPromises = packages.map((pkg) =>
    getPackageAvailability(
      appMetadata,
      environment,
      startDate,
      endDate,
      pkg.id,
    ),
  )
  const availabilities = await Promise.all(availabilityPromises)

  // Assign availabilities to packages
  packages.forEach((pkg, index) => {
    pkg.availability = availabilities[index]
  })

  const finalAvailability = resolvePackagesAvailability(packages)
  return finalAvailability
}

function getAssetsAndImpressionsForPackages(
  packages: Package.PackageDetail[],
  assets: { properties: Asset.AssetWithPackages }[],
): { assets: number; impressions: number } {
  const uniqueAssets: Map<number, Asset.AssetWithPackages> = new Map()
  let impressions = 0
  packages.forEach((pkg) => {
    assets.forEach((asset) => {
      if (
        Array.isArray(asset.properties.packages) &&
        asset.properties.packages?.includes(pkg.id) &&
        !uniqueAssets.has(asset.properties.internal_panel_id)
      ) {
        uniqueAssets.set(asset.properties.internal_panel_id, asset.properties)
        impressions += asset.properties.impressions
      }
    })
  })

  return { assets: uniqueAssets.size, impressions }
}

export async function preparePackageSelection(
  selectedPackages: Package.PackageTableData[],
  allPackages: Package.PackageWithAllData[],
  currentFilter: Package.PlanPackage,
): Promise<Package.PlanPackage> {
  const filteredPackages = allPackages.filter((pkg) =>
    selectedPackages.some((selected) => selected.id === pkg.package_id),
  )
  const packages: Package.PackageDetail[] = []
  let price = 0
  let productionDefaultValue = 0
  let assetAllocationDefaultValue = 0
  let materialHandlingDefaultValue = 0
  selectedPackages.forEach((data) => {
    const packageDetails = filteredPackages.find(
      (p) => p.package_id === data.id,
    )

    if (packageDetails === undefined) {
      throw new Error('Package details not found for package id: ' + data.id)
    }

    packages.push({
      id: data.id,
      name: data.name,
      availability: data.availability,
      price: {
        price: packageDetails.gross_price,
        production: packageDetails.production,
        posting: packageDetails.posting,
      },
      digital: packageDetails.digital ?? false,
    })
    if (packageDetails === undefined) {
      throw new Error('Package details not found for package id: ' + data.id)
    }
    price += packageDetails.gross_price
    productionDefaultValue += packageDetails.production
    assetAllocationDefaultValue += !packageDetails.digital
      ? packageDetails.posting
      : 0
    materialHandlingDefaultValue += packageDetails.digital
      ? packageDetails.posting
      : 0
  })

  const totalAvailability = resolvePackagesAvailability(packages)
  const currentFilterPrice = preparePriceModel(currentFilter.price)

  const totalPrice: Package.PlanPackagePrice = {
    ...currentFilterPrice, // We do a copy to keep the discounts set by the user
    grossPrice: price,
    fees: {
      production: productionDefaultValue,
      assetAllocation: assetAllocationDefaultValue,
      materialHandling: materialHandlingDefaultValue,
      photography: currentFilter?.price?.fees?.photography ?? 0,
    },
    totalNet: 0,
  }

  const totalPriceCalculated = getPriceValueUpdated(totalPrice)
  const assetsData = layerService.getDataFromAssetLayer()
  const { assets, impressions } = getAssetsAndImpressionsForPackages(
    packages,
    assetsData,
  )

  return {
    ...currentFilter,
    packages,
    price: totalPriceCalculated,
    assets,
    impressions,
    availability: totalAvailability,
  }
}

export function clearPackageSelection(
  planPackage: Package.PlanPackage,
): Package.PlanPackage {
  return {
    ...planPackage,
    packages: [],
    price: null,
    assets: 0,
    impressions: 0,
    availability: null,
  }
}
