import layerService from '@/layers/layerService'
import { HEATMAP_ASSETS_LAYER } from '@/layers'
import { Plan } from '@workspaces/types'

export function getIconSuffixNameBasedOnFacingDirection(
  facingDirection: string,
): string {
  switch (facingDirection) {
    case 'N':
      return '_N'
    case 'NE':
      return '_NE'
    case 'E':
      return '_E'
    case 'SE':
      return '_SE'
    case 'S':
      return '_S'
    case 'SW':
      return '_SW'
    case 'W':
      return '_W'
    case 'NW':
      return '_NW'
    default:
      // console.warn(`Unknown facing direction while selecting icon: ${facingDirection}`)
      return '_ND'
  }
}

export function getIconSuffixNameForCoLocated(): string {
  return '_all-directions'
}

export function getFacingOrientationKeyTranslation(
  facingDirection: string,
): string {
  switch (facingDirection) {
    case 'N':
      return 'NORTH'
    case 'NE':
      return 'NORTH_EAST'
    case 'E':
      return 'EAST'
    case 'SE':
      return 'SOUTH_EAST'
    case 'S':
      return 'SOUTH'
    case 'SW':
      return 'SOUTH_WEST'
    case 'W':
      return 'WEST'
    case 'NW':
      return 'NORTH_WEST'
    default:
      console.warn(
        `Unknown facing direction when looking key for translation: ${facingDirection}`,
      )
      return 'UNKNOWN_FACING_DIRECTION'
  }
}

export function manageHeatmapLayerVisibility(
  legend: Plan.Legend,
  updateDeckInstance: boolean,
): void {
  // legend props
  const legendHeatmapVisibility = legend.heatmapAssetsVisibility ?? false
  if (legendHeatmapVisibility) {
    layerService.showLayer(HEATMAP_ASSETS_LAYER, updateDeckInstance)
  } else {
    layerService.hideLayer(HEATMAP_ASSETS_LAYER, updateDeckInstance)
  }
}
