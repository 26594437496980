import { getAppMetadata } from '@/plan/metadata/metadata'
import { UserPermissions, UserInformation } from './permissions.manager.type'
import { getPermissionResolverVersion } from '@/plan/metadata/metadata.helper'
import { Auth, Plan } from '@workspaces/types'
import PermissionResolverEU from './resolvers/resolver-eu'
import PermissionResolverFI from './resolvers/resolver-fi'
import PermissionResolverUS from './resolvers/resolver-us'

class UserPermissionManager implements UserPermissions {
  private static instance: UserPermissions
  private permissionResolver: UserPermissions

  private constructor(userInformation: UserInformation) {
    const meta = getAppMetadata()

    // Get the correspondin message resolver based on metadata
    const permissionResolverVersion = getPermissionResolverVersion(meta)
    switch (permissionResolverVersion) {
      case Auth.PermissionResolver.US:
        this.permissionResolver = new PermissionResolverUS(userInformation)
        break

      case Auth.PermissionResolver.EU:
        this.permissionResolver = new PermissionResolverEU(userInformation)
        break

      case Auth.PermissionResolver.FI:
        this.permissionResolver = new PermissionResolverFI(userInformation)
        break

      default:
        throw new Error(
          `Unknown permission resolver version: ${permissionResolverVersion}`,
        )
    }
  }

  public static initialize(userInformation: UserInformation): UserPermissions {
    UserPermissionManager.instance = new UserPermissionManager(userInformation)
    return UserPermissionManager.instance
  }

  public static getInstance(): UserPermissions {
    if (!UserPermissionManager.instance) {
      throw new Error(
        'UserPermissionManager is not initialized. Must be initialized before using it',
      )
    }

    return UserPermissionManager.instance
  }

  public canUploadCustomPOIs(): boolean {
    return this.permissionResolver.canUploadCustomPOIs()
  }

  public canEditAnyCustomPOIGroup(): boolean {
    return this.permissionResolver.canEditAnyCustomPOIGroup()
  }

  public permitComments(planOwner: string): boolean {
    return this.permissionResolver.permitComments(planOwner)
  }

  public canSavePlan(
    planOwner: string,
    planId: string | undefined,
    isPlanPublic: boolean,
  ): boolean {
    return this.permissionResolver.canSavePlan(planOwner, planId, isPlanPublic)
  }

  public canSavePlanAsPublic(): boolean {
    return this.permissionResolver.canSavePlanAsPublic()
  }

  public canDeletePlan(plan: Plan.Plan): boolean {
    return this.permissionResolver.canDeletePlan(plan)
  }

  public canDeleteCustomGeoboundary(owner: string): boolean {
    return this.permissionResolver.canDeleteCustomGeoboundary(owner)
  }

  public canDeleteCustomPOIGroup(owner: string): boolean {
    return this.permissionResolver.canDeleteCustomPOIGroup(owner)
  }

  public canUpdateCustomPOIGroup(owner: string): boolean {
    return this.permissionResolver.canUpdateCustomPOIGroup(owner)
  }

  public canAdminEditPlan(plan: Plan.Plan): boolean {
    return this.permissionResolver.canAdminEditPlan(plan)
  }

  public canEditPlanPrice(plan: Plan.Plan): boolean {
    return this.permissionResolver.canEditPlanPrice(plan)
  }

  public canBookPlanPackage(plan: Plan.Plan): boolean {
    return this.permissionResolver.canBookPlanPackage(plan)
  }

  public canConfirmBookingPlanPackageByAdmin(plan: Plan.Plan): boolean {
    return this.permissionResolver.canConfirmBookingPlanPackageByAdmin(plan)
  }

  public canConfirmBookingPlanPackageByAgency(plan: Plan.Plan): boolean {
    return this.permissionResolver.canConfirmBookingPlanPackageByAgency(plan)
  }

  public canFinalizeConfirmBookingPlanPackageByAdmin(plan: Plan.Plan): boolean {
    return this.permissionResolver.canFinalizeConfirmBookingPlanPackageByAdmin(
      plan,
    )
  }

  public canCancelPlanPackageByAdmin(plan: Plan.Plan): boolean {
    return this.permissionResolver.canCancelPlanPackageByAdmin(plan)
  }

  public canShowWidgetUploadAssets(): boolean {
    return this.permissionResolver.canShowWidgetUploadAssets()
  }

  public canEditPlanPackageDates(plan: Plan.Plan): boolean {
    return this.permissionResolver.canEditPlanPackageDates(plan)
  }

  public canEditPlanPackagePrice(plan: Plan.Plan): boolean {
    return this.permissionResolver.canEditPlanPackagePrice(plan)
  }

  public canEditPlanPackageCustomPanels(plan: Plan.Plan): boolean {
    return this.permissionResolver.canEditPlanPackageCustomPanels(plan)
  }

  public canEditPlanPackagePanelTypes(plan: Plan.Plan): boolean {
    return this.permissionResolver.canEditPlanPackagePanelTypes(plan)
  }

  public canEditPlanPackagePackages(plan: Plan.Plan): boolean {
    return this.permissionResolver.canEditPlanPackagePackages(plan)
  }
}

export default UserPermissionManager
